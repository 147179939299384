import React, {
  FC, useCallback, useState,
} from 'react';
import { Modal, PopUpTypeMode } from '@/components/ui/Modal';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { CourseType } from '@/controllers/graphql/generated';
import {
  useProcessAgreementEmailMutation,
} from '@/components/landing/AgreementEmailPopup/graphql/generated/processAgreementEmail.mutation.generated';
import {
  useCollectSignUpData,
} from '@/controllers/auth/auth.hooks.ts/useCollectSignUpData';
import { analyticsSDK } from '@/controllers/analytics';
import {
  AgreementEmailPopupHarness,
} from '@/components/landing/AgreementEmailPopup/AgreementEmailPopup.harness';
import { AuthUserDocument } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { AgreementEmailForm } from './AgreementEmailForm';
import { AgreementStartLearning } from './AgreementStartLearning';
import styles from './AgreementEmailPopup.module.scss';

interface Props {
  isOpen: boolean;
  closePopup: () => void;
  courseType?: CourseType;
  courseSlug?: string;
}

export interface SendEmailOptions {
  email: string;
  newslettersSubscription?: boolean;
}

export enum Step {
  EMAIL_FORM = 'emailForm',
  START_LEARNING = 'startLearning',
}

export const AgreementEmailPopup: FC<Props> = ({
  isOpen,
  closePopup,
  courseType,
  courseSlug,
}) => {
  const collectSignUpData = useCollectSignUpData();

  const [step, setStep] = useState<Step>(Step.EMAIL_FORM);
  const [email, setEmail] = useState('');

  const { t } = useTranslation([I18N_CODES.courseAgreement]);

  const handleClosingPopup = useCallback(() => {
    closePopup();
  }, [closePopup]);

  const goToStartLearningStep = useCallback((userEmail: string) => {
    setEmail(userEmail);
    setStep(Step.START_LEARNING);
  }, []);

  const [
    processAgreementEmail,
  ] = useProcessAgreementEmailMutation();

  const sendEmail = useCallback(async (options: SendEmailOptions) => {
    const { email: userEmail, newslettersSubscription = false } = options;
    const signUpData = collectSignUpData();

    await processAgreementEmail({
      variables: {
        ...signUpData,
        email: userEmail,
        courseType: courseType ?? CourseType.Postpaid,
        newslettersSubscription,
      },
      refetchQueries: [
        { query: AuthUserDocument },
      ],
      awaitRefetchQueries: true,
    });

    analyticsSDK.landing.sendAgreementEmailAddressSubmittedEvent({
      email: userEmail,
    });
  }, [courseType, processAgreementEmail, collectSignUpData]);

  const {
    dataQa,
  } = new AgreementEmailPopupHarness();

  return (
    <Modal
      isOpen={isOpen}
      type={PopUpTypeMode.Default}
      className={styles.popup}
      onRequestClose={handleClosingPopup}
      renderTitle={() => (
        <p className={typography.platformH2}>
          {step === Step.EMAIL_FORM && t(`${I18N_CODES.courseAgreement}:agreement_email.modal_email_title`)}
          {step === Step.START_LEARNING && t(`${I18N_CODES.courseAgreement}:agreement_email.modal_start_learning_title`)}
        </p>
      )}
      titleClassNames={styles.modalTitle}
      dataQa={dataQa}
    >
      {step === Step.EMAIL_FORM && (
        <AgreementEmailForm
          goToNextStep={goToStartLearningStep}
          sendEmail={sendEmail}
        />
      )}

      {step === Step.START_LEARNING && (
        <AgreementStartLearning
          email={email}
          closePopup={handleClosingPopup}
          courseSlug={courseSlug}
        />
      )}
    </Modal>
  );
};
