import React, { FC } from 'react';
import { FormRenderProps } from 'react-final-form';
import { Button } from '@/components/ui/Button';
import { Loader } from '@/components/ui/Loader';
import { cn } from '@/lib/classNames';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { ROUTES } from '@/controllers/router/router.contants';
import {
  AgreementEmailPopupHarness,
} from '@/components/landing/AgreementEmailPopup/AgreementEmailPopup.harness';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import styles from './AgreementStartLearningRenderForm.module.scss';

interface Props extends FormRenderProps {
  email: string;
  courseSlug?: string;
}

export const AgreementStartLearningRenderForm: FC<Props> = (props) => {
  const {
    handleSubmit,
    submitting,
    email,
    courseSlug = ConsultationCourses.GeneralConsultation,
  } = props;

  const { t } = useTranslation([I18N_CODES.courseAgreement]);

  // Todo: add correct link to course depending on environment
  // Task: https://app.clickup.com/t/24383048/ACQ-8113
  const linkToCourse = `${ROUTES.user.learn}?course=${courseSlug}`;

  const {
    startLearningBodyDataQa,
  } = new AgreementEmailPopupHarness();

  return (
    <form onSubmit={handleSubmit}>
      <div
        data-qa={startLearningBodyDataQa}
        className={cn(styles.paragraph, 'mb-16')}
        dangerouslySetInnerHTML={{
          __html: t(`${I18N_CODES.courseAgreement}:agreement_email.modal_start_learning_body`, { email }),
        }}
      />

      <div>
        <Button
          type="submit"
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          text={t(`${I18N_CODES.courseAgreement}:agreement_email.modal_start_learning_button`)}
          disabled={submitting}
          data-qa="agreement-start-learning-submit-button"
          isLoading={submitting}
          hasFullWidth
          href={linkToCourse}
        />
      </div>

      <Loader
        loading={submitting}
        size={32}
      />
    </form>
  );
};
