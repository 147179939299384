import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { cn } from '@/lib/classNames';
import { ChubbsThumbsUp } from '../static/ChubbsThumbsUp';
import { AgreementStartLearningRenderForm } from '../AgreementStartLearningRenderForm';
import styles from './AgreementStartLearning.module.scss';

interface Props {
  closePopup: () => void;
  email: string;
  courseSlug?: string;
}

export const AgreementStartLearning: FC<Props> = ({
  closePopup,
  email,
  courseSlug,
}) => {
  const handleConfirmingPopup = useCallback(() => {
    closePopup();
  }, [closePopup]);

  return (
    <>
      <div className={cn(styles.imageWrapper, 'mb-16')}>
        <ChubbsThumbsUp />
      </div>

      <Form
        onSubmit={handleConfirmingPopup}
        subscription={{ submitting: true, pristine: true }}
        render={(formRenderProps) => (
          <AgreementStartLearningRenderForm
            {...formRenderProps}
            email={email}
            courseSlug={courseSlug}
          />
        )}
      />
    </>
  );
};
