import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect } from 'react';
import {
  GBExperimentVariants,
} from '@/components/services/GrowthBook/GrowthBook.constants';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { shouldShowGBConsoleMessages } from '@/components/services/GrowthBook/GrowthBook.helpers/GrowthBook.helpers';
import { GrowthBookClient } from '../GrowthBook.client';

interface Experiment {
  variant: number;
  ready: boolean;

  isControl: boolean;
  isVariant1: boolean;
  isVariant2: boolean;
  isDisabled: boolean;

  is: (variant: GBExperimentVariants) => boolean;
}

export const useGrowthBookExperiment = (featureName: string): Experiment => {
  const { gb } = GrowthBookClient.getInstance();

  const query = useRouterQuery();
  const isForceSelectedFromQuery = query && query[featureName];
  const shouldShowGBMessages = shouldShowGBConsoleMessages();

  const ready = isForceSelectedFromQuery || gb.ready;

  const featureValue = useFeatureValue(
    featureName,
    GBExperimentVariants.Disabled,
  );

  const variant = Number(isForceSelectedFromQuery
    ? query[featureName]
    : featureValue);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (shouldShowGBMessages && !ready) {
      // eslint-disable-next-line no-console
      console.log(`⏳⏳ Experiment loading ⏳⏳`);
    }

    if (shouldShowGBMessages && isForceSelectedFromQuery && ready) {
      // eslint-disable-next-line no-console
      console.log(`🚨🚨 Experiment force variant 🚨🚨`, {
        featureName,
        variant,
      });
    }
  }, [
    shouldShowGBMessages,
    isForceSelectedFromQuery,
    featureName,
    variant,
    ready,
  ]);

  return {
    variant,
    ready,

    isControl: variant === GBExperimentVariants.Control,
    isVariant1: variant === GBExperimentVariants.Variant1,
    isVariant2: variant === GBExperimentVariants.Variant2,
    isDisabled: variant === GBExperimentVariants.Disabled,

    is: (variantToCheck: GBExperimentVariants) => variant === variantToCheck,
  };
};
