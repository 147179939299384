import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export class AgreementEmailPopupHarness extends ComponentHarness {
  dataQa = 'agreement-email-popup';

  emailInputDataQa = 'agreement-email';

  submitButtonDataQa = 'agreement-email-submit-button';

  startLearningBodyDataQa = 'agreement-email-start-learning-body';

  get popup() {
    return cy.get(`[data-qa="${this.dataQa}"]`);
  }

  get emailInput() {
    return cy.get(`[data-qa="${this.emailInputDataQa}"]`);
  }

  get submitButton() {
    return cy.get(`[data-qa="${this.submitButtonDataQa}"]`);
  }

  get startLearningBody() {
    return cy.get(`[data-qa="${this.startLearningBodyDataQa}"]`);
  }

  allFirstStepUIElements() {
    return [
      this.popup,
      this.emailInput,
      this.submitButton,
    ];
  }
}
