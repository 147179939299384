import React, { memo, useMemo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  useShouldShowNoJobGuaranteePrepaid,
} from '@/components/courseLanding/CourseLandingPageModule/hooks/useShouldShowNoJobGuaranteePrepaid';
import {
  useShouldShowNoJobGuaranteeUA,
} from '@/components/courseLanding/CourseLandingPageModule/hooks/useShouldShowNoJobGuaranteeUA';
import {
  EmploymentBenefit,
} from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefits.typedefs';
import { IconEmojiBriefCase } from '@/components/ui/IconEmojies/IconEmojiBriefCase';
import { cn } from '@/lib';
import styles
  from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefits.module.scss';
import { IconEmojiHandshake } from '@/components/ui/IconEmojies/IconEmojiHandshake';
import { IconEmojiSparklingHeart } from '@/components/ui/IconEmojies/IconEmojiSparklingHeart';
import { IconEmojiStarStruck } from '@/components/ui/IconEmojies/IconEmojiStarStruck';
import { CourseType } from '@/controllers/graphql/generated';
import {
  EmploymentBenefitsTemplate,
} from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefitsTemplate';

export const EmploymentBenefitsPrepaid = memo(() => {
  const { t } = useTranslation([I18N_CODES.course]);

  // NO JOB GUARANTEE | START
  const shouldShowNoJobGuaranteePrepaid = useShouldShowNoJobGuaranteePrepaid({
    courseType: CourseType.Prepaid,
  });

  const {
    isVariant1: shouldShowNoJobGuaranteeUA,
  } = useShouldShowNoJobGuaranteeUA();

  const shouldShowNoJobGuarantee = (
    shouldShowNoJobGuaranteePrepaid || shouldShowNoJobGuaranteeUA
  );

  const titleModifier = shouldShowNoJobGuarantee
    ? 'no_guarantee'
    : '';
  // NO JOB GUARANTEE | END

  const benefits: EmploymentBenefit[] = useMemo(() => ([
    {
      id: 1,
      title: t(`${I18N_CODES.course}:employment_benefit_title_1`),
      description: t(`${I18N_CODES.course}:employment_benefit_description_1`),
      icon: <IconEmojiBriefCase className={cn(styles.purple, styles.circle)} />,
    },
    {
      id: 2,
      title: t(`${I18N_CODES.course}:employment_benefit_title_2`),
      description: t(`${I18N_CODES.course}:employment_benefit_description_2`),
      icon: <IconEmojiHandshake className={cn(styles.yellow, styles.circle)} />,
    },
    {
      id: 3,
      title: t(`${I18N_CODES.course}:employment_benefit_title_3`),
      description: t(`${I18N_CODES.course}:employment_benefit_description_3`),
      icon: (
        <IconEmojiSparklingHeart className={cn(styles.pink, styles.circle)} />
      ),
    },
    {
      id: 4,
      title: t(`${I18N_CODES.course}:employment_benefit_title_4`),
      description: t(`${I18N_CODES.course}:employment_benefit_description_4`),
      icon: <IconEmojiStarStruck className={cn(styles.blue, styles.circle)} />,
    },
    {
      id: 5,
      title: t(`${I18N_CODES.course}:employment_benefit_title_5`),
      description: t(`${I18N_CODES.course}:employment_benefit_description_5`),
      icon: <IconEmojiStarStruck className={cn(styles.green, styles.circle)} />,
    },
    {
      id: 6,
      title: t(`${I18N_CODES.course}:employment_benefit_title_6_prepaid`, {
        context: titleModifier,
      }),
      description: t(`${I18N_CODES.course}:employment_benefit_description_6_prepaid`),
      icon: <IconEmojiBriefCase className={cn(styles.orange, styles.circle)} />,
    },
  ]), [t, titleModifier]);

  return (
    <EmploymentBenefitsTemplate
      benefits={benefits}
    />
  );
});
