import React, { FC } from 'react';
import { FormRenderProps, useFormState } from 'react-final-form';
import {
  AgreementEmailField,
} from '@/components/landing/AgreementEmailPopup/AgreementEmailField';
import { Button } from '@/components/ui/Button';
import { Loader } from '@/components/ui/Loader';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  AgreementEmailPopupHarness,
} from '@/components/landing/AgreementEmailPopup/AgreementEmailPopup.harness';
import {
  SignUpConsentNote,
} from '@/components/authentication/SignUp/SignUpElements/SignUpConsentNote';
import {
  SignUpNewsletterSubscriptionCheckbox,
} from '@/components/authentication/SignUp/SignUpElements/SignUpNewsletterSubscriptionCheckbox';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

type Props = FormRenderProps;

export const AgreementEmailRenderForm: FC<Props> = (props) => {
  const {
    handleSubmit,
    submitting,
    pristine,
  } = props;

  const [authUser] = useAuthUser({ ssr: false });

  const { hasValidationErrors } = useFormState();

  const { t } = useTranslation([I18N_CODES.courseAgreement]);

  const {
    submitButtonDataQa,
  } = new AgreementEmailPopupHarness();

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-16">
        <AgreementEmailField />
      </div>

      {!authUser && (
        <div className="mb-16">
          <SignUpConsentNote buttonLabel={t(`${I18N_CODES.courseAgreement}:agreement_email.modal_email_button`)} />
        </div>
      )}

      <div className="mb-16">
        <SignUpNewsletterSubscriptionCheckbox />
      </div>

      <div>
        <Button
          dataQa={submitButtonDataQa}
          type="submit"
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          text={t(`${I18N_CODES.courseAgreement}:agreement_email.modal_email_button`)}
          disabled={submitting || pristine || hasValidationErrors}
          isLoading={submitting}
          hasFullWidth
          onClick={handleSubmit}
        />
      </div>

      <Loader
        loading={submitting}
        size={32}
      />
    </form>
  );
};
