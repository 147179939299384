import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FormField } from '@/components/ui/FormElements/FormField';
import { InputEmail } from '@/components/ui/FormElements/FormInputs/InputEmail';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  AgreementEmailPopupHarness,
} from '@/components/landing/AgreementEmailPopup/AgreementEmailPopup.harness';

export const AgreementEmailField: FC = () => {
  const fieldName = 'email';
  const { submitting } = useFormState();
  const { mutators } = useForm();
  const { t } = useTranslation([I18N_CODES.courseAgreement]);

  const {
    emailInputDataQa,
  } = new AgreementEmailPopupHarness();

  return (
    <FormField
      label={{
        for: 'agreement-email',
        text: t(`${I18N_CODES.courseAgreement}:agreement_email.modal_email_label`),
      }}
      validateOnChange
      onChange={(value) => {
        if (value) {
          mutators.trimInputValue?.(fieldName, value);
        }
      }}
      renderInput={(inputProps) => (
        <InputEmail
          config={{
            validate: makeValidator(
              isNotEmpty,
              'Email is required',
            ),
          }}
          {...inputProps}
          placeholder="example@mail.com"
          required
          data-qa={emailInputDataQa}
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
