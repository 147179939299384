import React, { FC } from 'react';

export const ChubbsThumbsUp: FC = () => (
  <svg width="170" height="89" viewBox="0 0 170 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M90.6808 56.886C97.6578 63.9786 102.797 72.1634 115.094 80.6694C119.051 83.4062 121.634 85.552 122.456 85.9118C122.456 85.9118 122.341 68.7713 122.341 68.2059C122.341 66.1244 115.556 64.2742 119.026 47.6477C119.315 45.8793 119.487 44.0936 119.54 42.3025C119.874 37.7668 123.536 37.0087 125.219 40.2467C126.028 41.7885 126.015 45.0008 125.848 46.8253C125.694 48.4057 127.236 47.3521 133.005 47.6091C133.612 47.5832 134.214 47.7335 134.737 48.0417C135.26 48.35 135.683 48.8031 135.955 49.3464C136.227 49.8897 136.335 50.5 136.268 51.1037C136.2 51.7074 135.96 52.2787 135.575 52.7487C135.434 52.9414 139.43 54.2392 136.86 58.248C140.098 61.8457 135.858 63.6574 135.755 64.287C134.971 69.1567 138.402 93.6339 136.462 98.6322C131.926 110.299 117.805 103.104 98.9042 88.8028C92.3384 83.8431 88.625 68.8741 90.5267 60.8563C90.6294 60.5994 90.6808 56.8732 90.6808 56.886Z" fill="#E77062" />
    <path d="M90.6808 56.886C97.6578 63.9786 102.797 72.1634 115.094 80.6694C119.051 83.4062 121.634 85.552 122.456 85.9117C122.456 85.9117 122.341 68.7713 122.341 68.2059C122.341 66.1244 115.556 64.2742 119.026 47.6477C119.315 45.8793 119.487 44.0936 119.54 42.3025C119.874 37.7668 123.536 37.0087 125.219 40.2467C126.028 41.7885 126.015 45.0008 125.848 46.8253C125.694 48.4057 127.236 47.3521 133.005 47.6091C133.612 47.5832 134.214 47.7335 134.737 48.0417C135.26 48.35 135.683 48.8031 135.955 49.3464C136.227 49.8897 136.335 50.5 136.268 51.1037C136.2 51.7074 135.96 52.2787 135.575 52.7487C135.434 52.9414 139.43 54.2392 136.86 58.248C140.098 61.8457 135.858 63.6574 135.755 64.287C134.971 69.1568 138.402 93.6339 136.462 98.6322C131.926 110.299 117.805 103.104 98.9042 88.8028C92.3384 83.8431 88.625 68.8741 90.5267 60.8563C90.6294 60.5994 90.6808 56.8732 90.6808 56.886Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M122.475 84.9992C122.475 85.5774 122.475 90.3701 122.475 91.7963C122.4 89.7073 121.883 87.6579 120.959 85.783C120.959 85.783 120.059 84.1897 120.265 84.3696C120.895 84.8003 121.566 85.1662 122.269 85.4618C122.295 85.3847 122.424 85.0121 122.475 84.9992Z" fill="#18181A" />
    <path d="M122.475 84.9992C122.475 85.5774 122.475 90.3701 122.475 91.7963C122.4 89.7073 121.883 87.6579 120.959 85.783C120.959 85.783 120.059 84.1897 120.265 84.3696C120.895 84.8003 121.566 85.1662 122.269 85.4618C122.295 85.3847 122.424 85.0121 122.475 84.9992Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M90.6621 57.7209L94.9922 61.7684C97.562 73.6922 101.417 80.682 103.421 92.3616C100.851 90.2287 98.9882 88.507 96.4184 86.3227L90.6621 57.7209Z" fill="#18181A" />
    <path d="M90.6621 57.7209L94.9922 61.7684C97.562 73.6922 101.417 80.682 103.421 92.3616C100.851 90.2287 98.9882 88.507 96.4184 86.3227L90.6621 57.7209Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M90.2306 18.9431C90.2306 29.9289 90.2306 29.749 90.2306 40.7863C90.2306 69.8377 103.439 75.9666 104.262 105.84C104.416 111.301 99.5332 110.62 98.698 117.957C92.5305 119.46 76.0197 113.434 69.8522 114.925C63.7104 113.434 47.4694 125.628 41.3147 124.137C30.5088 76.6733 53.3156 67.4349 53.2771 42.7136V18.9945C53.2385 -5.40561 90.2049 -4.55758 90.2306 18.9431Z" fill="#E77062" />
    <path d="M90.2306 18.9431C90.2306 29.9289 90.2306 29.749 90.2306 40.7863C90.2306 69.8377 103.439 75.9666 104.262 105.84C104.416 111.301 99.5332 110.62 98.698 117.957C92.5305 119.46 76.0197 113.434 69.8522 114.925C63.7104 113.434 47.4694 125.628 41.3147 124.137C30.5088 76.6733 53.3156 67.4349 53.2771 42.7136V18.9945C53.2385 -5.40561 90.2049 -4.55758 90.2306 18.9431Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M90.2199 18.9433C90.2199 29.9291 90.2199 29.7492 90.2199 40.7865C90.2099 44.0193 90.3901 47.25 90.7596 50.4617H52.5469C53.0471 47.9223 53.3009 45.3405 53.305 42.7523V19.0332C53.2279 -5.40542 90.1942 -4.55739 90.2199 18.9433Z" fill="#E77062" />
    <path d="M90.2199 18.9433C90.2199 29.9291 90.2199 29.7492 90.2199 40.7865C90.2099 44.0193 90.3901 47.25 90.7596 50.4617H52.5469C53.0471 47.9223 53.3009 45.3405 53.305 42.7523V19.0332C53.2279 -5.40542 90.1942 -4.55739 90.2199 18.9433Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M53.3613 17.0028C55.1859 -4.68616 88.1819 -3.97947 90.1349 17.0028H53.3613Z" fill="#E77062" />
    <path d="M53.3613 17.0028C55.1859 -4.68616 88.1819 -3.97947 90.1349 17.0028H53.3613Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M88.595 31.74C88.1706 31.74 87.7557 31.6142 87.4029 31.3784C87.05 31.1426 86.775 30.8075 86.6126 30.4154C86.4501 30.0233 86.4077 29.5919 86.4905 29.1756C86.5732 28.7594 86.7776 28.3771 87.0777 28.077C87.3778 27.7769 87.7601 27.5725 88.1764 27.4897C88.5926 27.4069 89.0241 27.4494 89.4161 27.6118C89.8082 27.7742 90.1434 28.0493 90.3791 28.4021C90.6149 28.755 90.7408 29.1699 90.7408 29.5943C90.7408 30.1633 90.5147 30.7091 90.1123 31.1115C89.7099 31.514 89.1641 31.74 88.595 31.74Z" fill="#FAFBFD" />
    <path d="M88.595 31.74C88.1706 31.74 87.7557 31.6142 87.4029 31.3784C87.05 31.1426 86.775 30.8075 86.6126 30.4154C86.4501 30.0233 86.4077 29.5919 86.4905 29.1756C86.5732 28.7594 86.7776 28.3771 87.0777 28.077C87.3778 27.7769 87.7601 27.5725 88.1764 27.4897C88.5926 27.4069 89.0241 27.4494 89.4161 27.6118C89.8082 27.7742 90.1434 28.0493 90.3791 28.4021C90.6149 28.755 90.7408 29.1699 90.7408 29.5943C90.7408 30.1633 90.5147 30.7091 90.1123 31.1115C89.7099 31.514 89.1641 31.74 88.595 31.74Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M70.5657 27.4485C70.9901 27.4485 71.4049 27.5743 71.7578 27.8101C72.1107 28.0459 72.3857 28.381 72.5481 28.7731C72.7105 29.1652 72.753 29.5966 72.6702 30.0129C72.5874 30.4291 72.3831 30.8115 72.083 31.1115C71.7829 31.4116 71.4006 31.616 70.9843 31.6988C70.5681 31.7816 70.1366 31.7391 69.7445 31.5767C69.3525 31.4143 69.0173 31.1393 68.7816 30.7864C68.5458 30.4335 68.4199 30.0186 68.4199 29.5943C68.4199 29.0252 68.646 28.4794 69.0484 28.077C69.4508 27.6746 69.9966 27.4485 70.5657 27.4485Z" fill="#FAFBFD" />
    <path d="M70.5657 27.4485C70.9901 27.4485 71.4049 27.5743 71.7578 27.8101C72.1107 28.0459 72.3857 28.381 72.5481 28.7731C72.7105 29.1652 72.753 29.5966 72.6702 30.0129C72.5874 30.4291 72.3831 30.8115 72.083 31.1115C71.7829 31.4116 71.4006 31.616 70.9843 31.6988C70.5681 31.7816 70.1366 31.7391 69.7445 31.5767C69.3525 31.4143 69.0173 31.1393 68.7816 30.7864C68.5458 30.4335 68.4199 30.0186 68.4199 29.5943C68.4199 29.0252 68.646 28.4794 69.0484 28.077C69.4508 27.6746 69.9966 27.4485 70.5657 27.4485Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M87.7852 21.2687C88.6317 21.1511 89.4941 21.2852 90.265 21.6542" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M71.474 21.2689C70.6274 21.149 69.7643 21.2831 68.9941 21.6543" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M53.6312 47.0701H90.2506C90.9407 47.0701 91.6025 47.3442 92.0905 47.8321C92.5784 48.3201 92.8525 48.9819 92.8525 49.672C92.8525 50.362 92.5784 51.0239 92.0905 51.5118C91.6025 51.9998 90.9407 52.2739 90.2506 52.2739H53.6312C52.9411 52.2739 52.2793 51.9998 51.7914 51.5118C51.3034 51.0239 51.0293 50.362 51.0293 49.672C51.0293 48.9819 51.3034 48.3201 51.7914 47.8321C52.2793 47.3442 52.9411 47.0701 53.6312 47.0701Z" fill="#E77062" />
    <path d="M53.6312 47.0701H90.2506C90.9407 47.0701 91.6025 47.3442 92.0905 47.8321C92.5784 48.3201 92.8525 48.9819 92.8525 49.672C92.8525 50.362 92.5784 51.0239 92.0905 51.5118C91.6025 51.9998 90.9407 52.2739 90.2506 52.2739H53.6312C52.9411 52.2739 52.2793 51.9998 51.7914 51.5118C51.3034 51.0239 51.0293 50.362 51.0293 49.672C51.0293 48.9819 51.3034 48.3201 51.7914 47.8321C52.2793 47.3442 52.9411 47.0701 53.6312 47.0701Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M53.2617 16.8104H109.604" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M90.7521 52.145L91.3046 54.4193C84.1673 53.2654 76.9678 52.5361 69.7441 52.235L90.7521 52.145Z" fill="#18181A" />
    <path d="M90.7521 52.145L91.3046 54.4193C84.1673 53.2654 76.9678 52.5361 69.7441 52.235L90.7521 52.145Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M89.6623 16.72L90.2148 18.9942C83.0775 17.8404 75.878 17.111 68.6543 16.8099L89.6623 16.72Z" fill="#18181A" />
    <path d="M89.6623 16.72L90.2148 18.9942C83.0775 17.8404 75.878 17.111 68.6543 16.8099L89.6623 16.72Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M88.3642 29.2224C88.4304 29.2223 88.4941 29.2477 88.5421 29.2933C88.5901 29.339 88.6188 29.4013 88.6221 29.4674C88.6221 29.5007 88.6154 29.5337 88.6023 29.5643C88.5892 29.5949 88.5699 29.6225 88.5458 29.6454C88.5217 29.6683 88.4931 29.6861 88.4619 29.6976C88.4307 29.7092 88.3974 29.7142 88.3642 29.7124C88.2992 29.7124 88.2369 29.6866 88.1909 29.6407C88.145 29.5947 88.1191 29.5324 88.1191 29.4674C88.1223 29.4035 88.1491 29.3429 88.1944 29.2976C88.2397 29.2524 88.3002 29.2255 88.3642 29.2224Z" fill="#18181A" />
    <path d="M88.3642 29.2224C88.4304 29.2223 88.4941 29.2477 88.5421 29.2933C88.5901 29.339 88.6188 29.4013 88.6221 29.4674C88.6221 29.5007 88.6154 29.5337 88.6023 29.5643C88.5892 29.5949 88.5699 29.6225 88.5458 29.6454C88.5217 29.6683 88.4931 29.6861 88.4619 29.6976C88.4307 29.7092 88.3974 29.7142 88.3642 29.7124C88.2992 29.7124 88.2369 29.6866 88.1909 29.6407C88.145 29.5947 88.1191 29.5324 88.1191 29.4674C88.1223 29.4035 88.1491 29.3429 88.1944 29.2976C88.2397 29.2524 88.3002 29.2255 88.3642 29.2224Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M70.6044 29.7381C70.5394 29.7381 70.4771 29.7123 70.4311 29.6663C70.3852 29.6204 70.3594 29.5581 70.3594 29.4931C70.3594 29.4281 70.3852 29.3658 70.4311 29.3198C70.4771 29.2739 70.5394 29.248 70.6044 29.248C70.6694 29.248 70.7317 29.2739 70.7776 29.3198C70.8236 29.3658 70.8494 29.4281 70.8494 29.4931C70.8494 29.5581 70.8236 29.6204 70.7776 29.6663C70.7317 29.7123 70.6694 29.7381 70.6044 29.7381Z" fill="#18181A" />
    <path d="M70.6044 29.7381C70.5394 29.7381 70.4771 29.7123 70.4311 29.6663C70.3852 29.6204 70.3594 29.5581 70.3594 29.4931C70.3594 29.4281 70.3852 29.3658 70.4311 29.3198C70.4771 29.2739 70.5394 29.248 70.6044 29.248C70.6694 29.248 70.7317 29.2739 70.7776 29.3198C70.8236 29.3658 70.8494 29.4281 70.8494 29.4931C70.8494 29.5581 70.8236 29.6204 70.7776 29.6663C70.7317 29.7123 70.6694 29.7381 70.6044 29.7381Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.6918 96.7811L57.0529 77.8032C56.706 78.7026 51.4765 84.3433 46.0799 91.6544C45.8743 91.9242 38.1136 104.927 38.4605 104.992C38.8074 105.056 38.6918 96.7811 38.6918 96.7811Z" fill="#18181A" />
    <path d="M38.6918 96.7811L57.0529 77.8032C56.706 78.7026 51.4765 84.3433 46.0799 91.6544C45.8743 91.9242 38.1136 104.927 38.4605 104.992C38.8074 105.056 38.6918 96.7811 38.6918 96.7811Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1575 127.26C9.50223 126.63 8.50002 134.339 7.44641 135.29C5.41628 137.089 3.23196 133.119 3.15487 131.744C3.15487 131.063 -2.92268 132.194 1.79288 122.904C3.2962 119.962 11.2368 110.286 13.6139 106.496C24.4712 89.0727 40.3011 67.4351 52.6232 59.456C54.345 58.351 58.1611 61.0621 58.9706 61.7816C60.2555 62.938 61.0907 64.5827 59.7158 70.4803C58.2767 76.6992 63.7247 75.0674 57.4544 75.4914C45.0295 89.8437 38.8106 96.7435 28.8527 113.062C26.7198 116.557 24.9209 118.831 24.3556 121.221C23.8673 123.238 24.574 125.23 23.6617 128.93C23.0578 131.5 16.8904 135.11 15.6183 133.427C14.796 132.322 14.9759 131.371 15.8625 126.938C16.0809 125.833 16.6848 121.349 16.1965 120.681C15.1429 119.011 11.134 124.818 10.1575 127.208" fill="#E77062" />
    <path d="M10.8142 125.95C9.44965 128.313 8.60837 130.942 8.34725 133.659C8.11597 135.535 6.76683 135.676 5.57188 135.175C3.90152 134.468 3.31047 132.605 3.85013 128.866C4.44958 125.684 5.66753 122.65 7.43498 119.936C8.46289 118.382 6.6255 116.853 8.14167 114.527C14.489 105.623 29.9848 79.8348 49.7208 60.8184" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.1543 127.259C11.1308 124.869 15.1397 119.062 16.1933 120.732C16.6815 121.4 16.0777 125.872 15.8592 126.99C15.5123 128.75 14.6514 130.613 14.5743 131.615C14.2788 135.033 19.8681 133.465 21.8211 131.332C25.5859 127.221 24.2367 121.94 24.3909 121.22C24.8149 118.779 27.3976 115.503 28.888 113.061C38.8459 96.7431 48.3798 87.5819 60.8305 73.2296" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M73.9629 35.8787C74.464 37.0865 75.8645 37.2406 77.0209 37.2406" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M126.911 47.7637C123.056 48.2262 122.041 53.6099 127.065 53.3787C129.031 53.2887 131.575 53.0703 131.408 53.1088C130.791 53.2502 122.992 53.6613 125.317 57.1048C126.782 59.2763 132.885 58.3897 132.68 58.3897C131.37 58.5162 130.092 58.8683 128.902 59.4305C125.292 60.8696 126.217 63.9148 129.75 64.2231C130.65 64.3002 131.947 64.3259 132.885 64.3259" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
  </svg>
);
