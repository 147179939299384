import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { cn } from '@/lib/classNames';
import { SendEmailOptions } from '@/components/landing/AgreementEmailPopup';
import { AgreementEmailRenderForm } from '../AgreementEmailRenderForm';
import { ChubbsMessage } from '../static/ChubbsMessage';
import styles from './AgreementEmailForm.module.scss';

interface Props {
  goToNextStep: (email: string) => void;
  sendEmail: (options: SendEmailOptions) => Promise<void>;
}

interface AgreementEmailFormFields {
  email?: string;
  newslettersSubscription?: boolean;
}

export const AgreementEmailForm: FC<Props> = ({
  goToNextStep,
  sendEmail,
}) => {
  const handleConfirmingPopup = useCallback(
    async (data: AgreementEmailFormFields) => {
      const { email = '', newslettersSubscription = false } = data;

      await sendEmail({ email, newslettersSubscription });

      goToNextStep(email);
    },
    [goToNextStep, sendEmail],
  );

  return (
    <>
      <div className={cn(styles.imageWrapper, 'mb-16')}>
        <ChubbsMessage />
      </div>

      <Form
        onSubmit={handleConfirmingPopup}
        subscription={{ submitting: true, pristine: true }}
        mutators={{
          trimInputValue: ([name], state, { changeValue }) => {
            changeValue(state, name, (value) => value.trim());
          },
        }}
        render={(formRenderProps) => (
          <AgreementEmailRenderForm
            {...formRenderProps}
          />
        )}
      />
    </>
  );
};
