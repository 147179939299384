import {
  useGrowthBookExperiment,
} from '@/components/services/GrowthBook/GrowthBook.hooks/useGrowthBookExperiment';
import {
  GBExperiments,
} from '@/components/services/GrowthBook/GrowthBook.constants';

export const useShouldShowNoJobGuaranteeUA = () => {
  const { ready: isReady, isVariant1 } = useGrowthBookExperiment(
    GBExperiments.noJobGuaranteeTagUA.feature,
  );

  return {
    isReady,
    isVariant1,
  };
};
