import React, { FC } from 'react';

export const ChubbsMessage: FC = () => (
  <svg width="300" height="96" viewBox="0 0 300 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M134.533 53.8603C134.666 53.6311 138.562 51.5925 139.093 55.8386C139.334 57.7807 137.248 60.8447 136.813 60.3863C136.813 60.3863 134.184 54.4152 134.533 53.8603Z" fill="#E77062" />
    <path d="M134.533 53.8603C134.666 53.6311 138.562 51.5925 139.093 55.8386C139.334 57.7807 137.248 60.8447 136.813 60.3863C136.813 60.3863 134.184 54.4152 134.533 53.8603Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M174.771 65.1147C169.005 70.9772 152.696 86.6951 151.453 87.9376C151.453 87.9376 145.012 76.2728 145.012 75.7541C145.012 74.4634 144.3 75.3199 145.832 69.5297C146.214 68.2593 146.201 66.9027 145.793 65.6401C145.386 64.3775 144.605 63.2687 143.552 62.4609C140.343 59.6382 137.219 61.0133 138.727 64.8011C139.319 65.9033 140.067 66.9149 140.947 67.8047V71.1582C117.629 63.7637 126.845 69.2643 128.522 74.9459C130.934 83.0039 137.074 95.6578 138.908 100.362C141.731 107.6 148.221 107.105 157.449 102.003C162.129 99.4093 161.067 99.5903 167.039 95.115C173.203 90.4466 176.689 76.3935 174.903 68.8783C174.819 68.6009 174.771 65.1147 174.771 65.1147Z" fill="#E77062" />
    <path d="M174.771 65.1147C169.005 70.9772 152.696 86.6951 151.453 87.9376C151.453 87.9376 145.012 76.2728 145.012 75.7541C145.012 74.4634 144.3 75.3199 145.832 69.5297C146.214 68.2593 146.201 66.9027 145.793 65.6401C145.386 64.3775 144.605 63.2687 143.552 62.4609C140.343 59.6382 137.219 61.0133 138.727 64.8011C139.319 65.9033 140.067 66.9149 140.947 67.8047V71.1582C117.629 63.7637 126.845 69.2643 128.522 74.9459C130.934 83.0039 137.074 95.6578 138.908 100.362C141.731 107.6 148.221 107.105 157.449 102.003C162.129 99.4093 161.067 99.5903 167.039 95.115C173.203 90.4466 176.689 76.3935 174.903 68.8783C174.819 68.6009 174.771 65.1147 174.771 65.1147Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M130.741 51.2578L122.079 54.9296C120.514 55.5926 119.784 57.398 120.447 58.9622L128.487 77.9318C129.15 79.4959 130.956 80.2265 132.52 79.5635L141.183 75.8917C142.747 75.2287 143.477 73.4233 142.814 71.8591L134.774 52.8895C134.111 51.3253 132.306 50.5948 130.741 51.2578Z" fill="#18181A" />
    <path d="M130.741 51.2578L122.079 54.9296C120.514 55.5926 119.784 57.398 120.447 58.9622L128.487 77.9318C129.15 79.4959 130.956 80.2265 132.52 79.5635L141.183 75.8917C142.747 75.2287 143.477 73.4233 142.814 71.8591L134.774 52.8895C134.111 51.3253 132.306 50.5948 130.741 51.2578Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M151.486 87.9251L153.983 93.7997C153.278 91.9675 152.978 90.0048 153.102 88.0457C153.102 88.0457 153.44 86.5379 153.187 86.5741L151.486 87.9251Z" fill="#18181A" />
    <path d="M151.486 87.9251L153.983 93.7997C153.278 91.9675 152.978 90.0048 153.102 88.0457C153.102 88.0457 153.44 86.5379 153.187 86.5741L151.486 87.9251Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M174.787 65.8626L170.722 69.6745C168.31 80.8567 164.691 87.4189 162.809 98.384C165.222 96.3816 166.971 94.7652 169.383 92.7145L174.787 65.8626Z" fill="#18181A" />
    <path d="M174.787 65.8626L170.722 69.6745C168.31 80.8567 164.691 87.4189 162.809 98.384C165.222 96.3816 166.971 94.7652 169.383 92.7145L174.787 65.8626Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M175.2 29.4929C175.2 39.8187 175.2 39.6378 175.2 49.9997C175.2 77.2617 162.8 83.0157 162.028 111.062C161.883 116.188 166.467 115.549 167.251 122.437C173.041 123.848 188.53 118.191 194.332 119.602C200.098 118.191 215.345 129.651 221.123 128.239C231.268 83.6791 209.857 75.006 209.893 51.8091V29.5291C209.893 6.64592 175.224 7.43001 175.2 29.4929Z" fill="#E77062" />
    <path d="M175.2 29.4929C175.2 39.8187 175.2 39.6378 175.2 49.9997C175.2 77.2617 162.8 83.0157 162.028 111.062C161.883 116.188 166.467 115.549 167.251 122.437C173.041 123.848 188.53 118.191 194.332 119.602C200.098 118.191 215.345 129.651 221.123 128.239C231.268 83.6791 209.857 75.006 209.893 51.8091V29.5291C209.893 6.64592 175.224 7.43001 175.2 29.4929Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M175.204 29.493C175.204 39.8188 175.204 39.6378 175.204 49.9998C175.213 53.0309 175.044 56.0598 174.697 59.071H210.608C210.139 56.687 209.9 54.2632 209.897 51.8333V29.5533C209.897 6.64599 175.228 7.43007 175.204 29.493Z" fill="#E77062" />
    <path d="M175.204 29.493C175.204 39.8188 175.204 39.6378 175.204 49.9998C175.213 53.0309 175.044 56.0598 174.697 59.071H210.608C210.139 56.687 209.9 54.2632 209.897 51.8333V29.5533C209.897 6.64599 175.228 7.43007 175.204 29.493Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M209.812 27.6716C208.099 7.32164 177.122 7.97304 175.288 27.6716H209.812Z" fill="#E77062" />
    <path d="M209.812 27.6716C208.099 7.32164 177.122 7.97304 175.288 27.6716H209.812Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M176.737 41.4715C177.139 41.4739 177.532 41.3568 177.868 41.135C178.203 40.9132 178.464 40.5968 178.619 40.2261C178.774 39.8553 178.815 39.4468 178.737 39.0526C178.66 38.6583 178.466 38.2961 178.182 38.012C177.898 37.7278 177.536 37.5346 177.142 37.4568C176.747 37.379 176.339 37.4201 175.968 37.575C175.597 37.7299 175.281 37.9915 175.059 38.3266C174.837 38.6617 174.72 39.0552 174.723 39.457C174.723 39.9913 174.935 40.5037 175.313 40.8815C175.691 41.2593 176.203 41.4715 176.737 41.4715Z" fill="white" />
    <path d="M176.737 41.4715C177.139 41.4739 177.532 41.3568 177.868 41.135C178.203 40.9132 178.464 40.5968 178.619 40.2261C178.774 39.8553 178.815 39.4468 178.737 39.0526C178.66 38.6583 178.466 38.2961 178.182 38.012C177.898 37.7278 177.536 37.5346 177.142 37.4568C176.747 37.379 176.339 37.4201 175.968 37.575C175.597 37.7299 175.281 37.9915 175.059 38.3266C174.837 38.6617 174.72 39.0552 174.723 39.457C174.723 39.9913 174.935 40.5037 175.313 40.8815C175.691 41.2593 176.203 41.4715 176.737 41.4715Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M193.661 37.4786C193.26 37.4762 192.867 37.593 192.532 37.8142C192.197 38.0355 191.936 38.3511 191.781 38.7212C191.625 39.0913 191.583 39.4991 191.66 39.8931C191.737 40.287 191.929 40.6492 192.212 40.9338C192.495 41.2184 192.856 41.4126 193.25 41.4918C193.643 41.571 194.051 41.5316 194.422 41.3786C194.793 41.2255 195.11 40.9658 195.334 40.6323C195.557 40.2988 195.676 39.9065 195.676 39.5051C195.678 39.2396 195.627 38.9763 195.526 38.7305C195.426 38.4847 195.277 38.2612 195.09 38.0729C194.903 37.8845 194.68 37.7351 194.435 37.6331C194.19 37.5311 193.927 37.4786 193.661 37.4786Z" fill="white" />
    <path d="M193.661 37.4786C193.26 37.4762 192.867 37.593 192.532 37.8142C192.197 38.0355 191.936 38.3511 191.781 38.7212C191.625 39.0913 191.583 39.4991 191.66 39.8931C191.737 40.287 191.929 40.6492 192.212 40.9338C192.495 41.2184 192.856 41.4126 193.25 41.4918C193.643 41.571 194.051 41.5316 194.422 41.3786C194.793 41.2255 195.11 40.9658 195.334 40.6323C195.557 40.2988 195.676 39.9065 195.676 39.5051C195.678 39.2396 195.627 38.9763 195.526 38.7305C195.426 38.4847 195.277 38.2612 195.09 38.0729C194.903 37.8845 194.68 37.7351 194.435 37.6331C194.19 37.5311 193.927 37.4786 193.661 37.4786Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M177.489 31.6761C176.695 31.5657 175.885 31.6916 175.161 32.038" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M192.796 31.6762C193.59 31.5636 194.401 31.6896 195.124 32.0381" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M209.57 55.9471H175.191C174.543 55.9471 173.922 56.2044 173.464 56.6625C173.006 57.1206 172.748 57.7419 172.748 58.3898C172.748 59.0376 173.006 59.6589 173.464 60.117C173.922 60.5751 174.543 60.8325 175.191 60.8325H209.57C210.218 60.8325 210.839 60.5751 211.297 60.117C211.755 59.6589 212.013 59.0376 212.013 58.3898C212.013 57.7419 211.755 57.1206 211.297 56.6625C210.839 56.2044 210.218 55.9471 209.57 55.9471Z" fill="#E77062" />
    <path d="M209.57 55.9471H175.191C174.543 55.9471 173.922 56.2044 173.464 56.6625C173.006 57.1206 172.748 57.7419 172.748 58.3898C172.748 59.0376 173.006 59.6589 173.464 60.117C173.922 60.5751 174.543 60.8325 175.191 60.8325H209.57C210.218 60.8325 210.839 60.5751 211.297 60.117C211.755 59.6589 212.013 59.0376 212.013 58.3898C212.013 57.7419 211.755 57.1206 211.297 56.6625C210.839 56.2044 210.218 55.9471 209.57 55.9471Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M209.891 27.4907H156.996" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M174.701 60.6752L174.183 62.7983C180.886 61.7381 187.644 61.0615 194.424 60.7717L174.701 60.6752Z" fill="#18181A" />
    <path d="M174.701 60.6752L174.183 62.7983C180.886 61.7381 187.644 61.0615 194.424 60.7717L174.701 60.6752Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M175.731 27.4058L175.212 29.5409C181.903 28.4741 188.649 27.7894 195.417 27.4903L175.731 27.4058Z" fill="#18181A" />
    <path d="M175.731 27.4058L175.212 29.5409C181.903 28.4741 188.649 27.7894 195.417 27.4903L175.731 27.4058Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M175.838 39.6985C175.808 39.6967 175.777 39.7015 175.748 39.7124C175.72 39.7233 175.694 39.7401 175.672 39.7617C175.65 39.7834 175.634 39.8093 175.623 39.8379C175.612 39.8665 175.607 39.8971 175.609 39.9277C175.609 39.9885 175.633 40.0467 175.676 40.0897C175.719 40.1327 175.777 40.1569 175.838 40.1569C175.898 40.1539 175.955 40.1289 175.997 40.0865C176.039 40.0441 176.064 39.9875 176.067 39.9277C176.067 39.8669 176.043 39.8086 176 39.7656C175.957 39.7226 175.899 39.6985 175.838 39.6985Z" fill="#18181A" />
    <path d="M175.838 39.6985C175.808 39.6967 175.777 39.7015 175.748 39.7124C175.72 39.7233 175.694 39.7401 175.672 39.7617C175.65 39.7834 175.634 39.8093 175.623 39.8379C175.612 39.8665 175.607 39.8971 175.609 39.9277C175.609 39.9885 175.633 40.0467 175.676 40.0897C175.719 40.1327 175.777 40.1569 175.838 40.1569C175.898 40.1539 175.955 40.1289 175.997 40.0865C176.039 40.0441 176.064 39.9875 176.067 39.9277C176.067 39.8669 176.043 39.8086 176 39.7656C175.957 39.7226 175.899 39.6985 175.838 39.6985Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M192.519 40.1686C192.549 40.1704 192.58 40.1657 192.608 40.1548C192.637 40.1439 192.663 40.127 192.685 40.1054C192.706 40.0838 192.723 40.0578 192.734 40.0292C192.745 40.0006 192.749 39.97 192.748 39.9394C192.748 39.8787 192.724 39.8204 192.681 39.7774C192.638 39.7344 192.579 39.7103 192.519 39.7103C192.459 39.7132 192.402 39.7383 192.36 39.7806C192.317 39.823 192.292 39.8796 192.289 39.9394C192.289 40.0002 192.314 40.0585 192.356 40.1015C192.399 40.1445 192.458 40.1686 192.519 40.1686Z" fill="#18181A" />
    <path d="M192.519 40.1686C192.549 40.1704 192.58 40.1657 192.608 40.1548C192.637 40.1439 192.663 40.127 192.685 40.1054C192.706 40.0838 192.723 40.0578 192.734 40.0292C192.745 40.0006 192.749 39.97 192.748 39.9394C192.748 39.8787 192.724 39.8204 192.681 39.7774C192.638 39.7344 192.579 39.7103 192.519 39.7103C192.459 39.7132 192.402 39.7383 192.36 39.7806C192.317 39.823 192.292 39.8796 192.289 39.9394C192.289 40.0002 192.314 40.0585 192.356 40.1015C192.399 40.1445 192.458 40.1686 192.519 40.1686Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M182.063 34.9209H209.88" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M186.231 34.9209H199.163V36.7062C199.163 38.4211 198.481 40.0656 197.269 41.2782C196.056 42.4907 194.412 43.1719 192.697 43.1719C190.982 43.1719 189.338 42.4907 188.125 41.2782C186.913 40.0656 186.231 38.4211 186.231 36.7062V34.9209Z" fill="#18181A" />
    <path d="M186.231 34.9209H199.163V36.7062C199.163 38.4211 198.481 40.0656 197.269 41.2782C196.056 42.4907 194.412 43.1719 192.697 43.1719C190.982 43.1719 189.338 42.4907 188.125 41.2782C186.913 40.0656 186.231 38.4211 186.231 36.7062V34.9209Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M170.917 34.9209H183.849V36.7062C183.849 38.4211 183.167 40.0656 181.955 41.2782C180.742 42.4907 179.098 43.1719 177.383 43.1719C175.668 43.1719 174.024 42.4907 172.811 41.2782C171.599 40.0656 170.917 38.4211 170.917 36.7062V34.9209Z" fill="#18181A" />
    <path d="M170.917 34.9209H183.849V36.7062C183.849 38.4211 183.167 40.0656 181.955 41.2782C180.742 42.4907 179.098 43.1719 177.383 43.1719C175.668 43.1719 174.024 42.4907 172.811 41.2782C171.599 40.0656 170.917 38.4211 170.917 36.7062V34.9209Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M195.749 48.1062C196.144 47.9011 196.495 47.6196 196.781 47.2779C197.066 46.9362 197.281 46.5413 197.413 46.1158" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M196.221 45.3196C196.581 45.6179 197.002 45.8349 197.454 45.9557C197.905 46.0764 198.378 46.0981 198.839 46.0192" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M145.641 77.2976C150.816 79.9514 156.245 81.6643 161.962 84.2457C166.075 86.1582 170.393 87.5911 174.833 88.516C179.26 89.288 182.071 89.6137 185.557 88.3712C190.575 86.5859 209.49 78.2264 210.756 75.9706C214.158 69.9392 210.298 63.5218 203.253 63.4374L180.032 74.2939L152.722 64.0526C149.284 57.418 144.278 60.9886 144.037 60.675L137.776 58.19C135.967 57.4663 131.853 56.2962 131.323 59.2516C130.901 61.5676 137.583 63.5339 137.583 63.5339C133.12 64.523 136.377 68.359 136.377 68.359C134.097 70.4338 135.171 72.3639 138.428 74.1854C140.527 75.3916 144.544 76.7306 145.665 77.3217" fill="#E77062" />
    <path d="M203.253 63.4374L180.032 74.2939L152.722 64.0526C149.284 57.418 144.278 60.9886 144.037 60.675L137.776 58.19C135.967 57.4663 131.853 56.2962 131.323 59.2516C130.901 61.5676 137.583 63.5339 137.583 63.5339C133.12 64.523 136.377 68.359 136.377 68.359C134.097 70.4338 135.171 72.3639 138.428 74.1854C140.527 75.3916 144.544 76.7306 145.665 77.3217C150.84 79.9755 156.269 81.6884 161.986 84.2699C166.092 86.1723 170.403 87.597 174.833 88.516C179.26 89.288 182.662 89.4086 186.124 88.1662C191.13 86.3809 209.49 78.2264 210.756 75.9706" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M136.855 63.2924L140.8 64.6193" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M143.875 60.5671L146.698 61.6045" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M136.332 68.3353L140.18 69.5416" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M121.831 62.2676C121.928 62.5209 129.805 62.919 127.476 66.3931C127.726 66.4359 127.972 66.4964 128.212 66.574C128.603 66.7147 128.94 66.9757 129.173 67.3195C129.407 67.6633 129.525 68.0722 129.512 68.4876C129.499 68.903 129.354 69.3035 129.099 69.6316C128.844 69.9596 128.491 70.1983 128.092 70.3135C128.092 70.3135 130.613 70.0119 130.504 72.0264C130.432 74.3666 125.595 74.1374 124.027 73.679C123.514 73.5847 123.048 73.3202 122.704 72.9284C122.361 72.5366 122.159 72.0403 122.133 71.5198C122.036 70.5065 122.748 71.5198 120.854 69.9878C118.96 68.4558 120.589 66.4534 120.046 65.9468C118.707 64.7405 117.633 61.8816 120.866 61.0613C120.938 61.0492 121.831 62.2676 121.831 62.2676Z" fill="#E77062" />
    <path d="M121.831 62.2676C121.928 62.5209 129.805 62.919 127.476 66.3931C127.726 66.4359 127.972 66.4964 128.212 66.574C128.603 66.7147 128.94 66.9757 129.173 67.3195C129.407 67.6633 129.525 68.0722 129.512 68.4876C129.499 68.903 129.354 69.3035 129.099 69.6316C128.844 69.9596 128.491 70.1983 128.092 70.3135C128.092 70.3135 130.613 70.0119 130.504 72.0264C130.432 74.3666 125.595 74.1374 124.027 73.679C123.514 73.5847 123.048 73.3202 122.704 72.9284C122.361 72.5366 122.159 72.0403 122.133 71.5198C122.036 70.5065 122.748 71.5198 120.854 69.9878C118.96 68.4558 120.589 66.4534 120.046 65.9468C118.707 64.7405 117.633 61.8816 120.866 61.0613C120.938 61.0492 121.831 62.2676 121.831 62.2676Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M120.056 65.9101C120.473 66.1245 120.915 66.2866 121.371 66.3926C123.301 66.856 125.307 66.9054 127.258 66.5374H127.403" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path d="M121.837 70.6639C123.936 70.6639 126.662 70.9776 128.568 70.1332C128.615 70.116 128.66 70.0916 128.7 70.0608" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M166.46 86.5741L165.821 88.4318C170.381 89.6381 175.881 90.7237 180.61 90.2412C184.381 89.7187 188.009 88.4517 191.286 86.5138C192.755 85.6321 194.269 84.8268 195.821 84.1012C195.616 84.1012 186.412 89.2641 179.512 89.2641C177.388 89.244 175.277 88.9273 173.24 88.3232L166.46 86.5741Z" fill="#18181A" />
    <path d="M166.46 86.5741L165.821 88.4318C170.381 89.6381 175.881 90.7237 180.61 90.2412C184.381 89.7187 188.009 88.4517 191.286 86.5138C192.755 85.6321 194.269 84.8268 195.821 84.1012C195.616 84.1012 186.412 89.2641 179.512 89.2641C177.388 89.244 175.277 88.9273 173.24 88.3232L166.46 86.5741Z" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M81.4973 10.4576L114.525 24.161L116.986 16.0065L81.4973 10.4576Z" fill="#18181A" />
    <path d="M81.4973 10.4576L114.525 24.161L116.986 16.0065L81.4973 10.4576Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M88.762 13.0274L113.877 18.1541L114.709 24.2458L88.762 13.0274Z" fill="#E77062" />
    <path d="M88.762 13.0274L113.877 18.1541L114.709 24.2458L88.762 13.0274Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M103.199 24.39L113.875 18.1535L75.3464 9.45621L103.199 24.39Z" fill="#E77062" />
    <path d="M103.199 24.39L113.875 18.1535L75.3464 9.45621L103.199 24.39Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M116.698 16.1752L127.385 9.92666L75.3464 9.45621L116.698 16.1752Z" fill="#E77062" />
    <path d="M116.698 16.1752L127.385 9.92666L75.3464 9.45621L116.698 16.1752Z" stroke="#18181A" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M120.33 17.9727C123.61 19.3284 126.435 21.5925 128.472 24.4987C131.488 28.8052 132.526 39.2878 126.639 41.6159C122.429 43.2926 117.242 37.1165 119.498 35.1985C122.345 32.7859 126.542 40.2166 126.217 48.6244" stroke="#18181A" strokeWidth="0.6" strokeMiterlimit="22.93" strokeLinecap="round" />
  </svg>
);
