import React, { memo, useMemo } from 'react';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { analyticsSDK } from '@/controllers/analytics';
import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import {
  useShouldShowNoJobGuaranteeUA,
} from '@/components/courseLanding/CourseLandingPageModule/hooks/useShouldShowNoJobGuaranteeUA';
import {
  useShouldShowNoJobGuaranteePrepaid,
} from '@/components/courseLanding/CourseLandingPageModule/hooks/useShouldShowNoJobGuaranteePrepaid';
import styles from '@/components/courseLanding/EmploymentGuarantee/EmploymentGuarantee.module.scss';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { IconAttach } from '@/components/ui/icons/IconAtach';
import { CourseHeroSticker, StickerColorMode, StickerSide } from '@/components/courseLanding/CourseHeroSticker';
import { AgreementEmailPopup } from '@/components/landing/AgreementEmailPopup';
import { CourseType } from '@/controllers/graphql/generated';
import {
  EmploymentBenefitsPrepaid,
} from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefitsPrepaid';
import { getAgreementLink } from '@/controllers/agreement/getAgreementLink';

interface Props {
  id?: string;
  courseSlug?: string;
}

export const EmploymentGuaranteePrepaid = memo<Props>((props) => {
  const {
    id,
    courseSlug,
  } = props;

  const [authUser] = useAuthUser({ ssr: false });
  const isAuthUser = Boolean(authUser);

  const { t } = useTranslation([
    I18N_CODES.price,
    I18N_CODES.course,
    I18N_CODES.aboutUs,
  ]);

  const agreementLink = getAgreementLink({
    courseType: CourseType.Prepaid,
    courseSlug,
  });

  const [
    isAgreementEmailModalOpened,
    closeAgreementEmailModal,
    openAgreementEmailModal,
  ] = useIsOpenStateDeprecated();

  const handleOpenAgreementEmailModal = async () => {
    analyticsSDK.landing.sendAgreementButtonClickedEvent({
      courseType: CourseType.Prepaid,
    });

    openAgreementEmailModal();
  };

  const shouldOpenModal = !isAuthUser;

  const formatter = useNumberFormatter();
  const studentsCount = useMemo(() => (
    formatter.customFormat(Number(t(`${I18N_CODES.common}:employed_students_count`)))
  ), [t, formatter]);

  const companiesCount = useMemo(() => (
    formatter.customFormat(Number(t(`${I18N_CODES.common}:students_get_job_companies_count`)))
  ), [t, formatter]);

  // NO JOB GUARANTEE | START
  const {
    isReady,
    isVariant1,
  } = useShouldShowNoJobGuaranteeUA();

  const shouldShowNoJobGuaranteeUA = isReady && isVariant1;

  const shouldShowNoJobGuaranteePrepaid = useShouldShowNoJobGuaranteePrepaid({
    courseType: CourseType.Prepaid,
  });

  const shouldShowNoJobGuarantee = (
    shouldShowNoJobGuaranteeUA
    || shouldShowNoJobGuaranteePrepaid
  );

  const codeModifier = shouldShowNoJobGuarantee
    ? 'no_guarantee'
    : '';
  // NO JOB GUARANTEE | END

  return (
    <div className={styles.sectionContainer} id={id}>
      <div className='grid-container'>
        <div className="grid-x align-center">
          <div className="cell xlarge-10">
            <div className={cn(styles.guaranteeContentWrapper, 'mb-32 small-mb-24')}>
              <div className={styles.guarantyTextWrapper}>
                <h2 className={cn(typography.landingH2, styles.title, 'mb-16')} data-qa="employment-guarantee-title">
                  {t(`${I18N_CODES.course}:employment_guarantee_title_prepaid`, {
                    context: codeModifier,
                  })}
                </h2>

                <p className={cn(typography.landingTextMain, styles.subtitle, 'mb-32 small-mb-24')} data-qa="employment-guarantee-description">
                  {t(`${I18N_CODES.course}:employment_guarantee_description`)}
                </p>

                {shouldOpenModal && (
                  <Button
                    className={styles.agreementButton}
                    size={Button.size.Large}
                    mode={Button.mode.Secondary}
                    data-qa="flex-receive-link"
                    text={t(`${I18N_CODES.price}:agreement_link`)}
                    LeftIcon={IconAttach}
                    onClick={handleOpenAgreementEmailModal}
                  />
                )}

                {!shouldOpenModal && (
                  <Button
                    className={styles.agreementButton}
                    size={Button.size.Large}
                    mode={Button.mode.Secondary}
                    data-qa="flex-agreement-link"
                    href={agreementLink}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    text={t(`${I18N_CODES.price}:agreement_link`)}
                    LeftIcon={IconAttach}
                  />
                )}
              </div>

              <div className={styles.guaranteeStatisticWrapper}>
                <div className={cn(styles.statisticItem, styles.green)} data-qa="employed-students-block">
                  {`${studentsCount}+`}

                  <div className={styles.firstItem}>
                    <CourseHeroSticker
                      colorMode={StickerColorMode.Green}
                      title={t(`${I18N_CODES.course}:employed_students_sticker_title`)}
                      width='227px'
                    />
                  </div>
                </div>

                <div className={styles.divider} />

                <div className={cn(styles.statisticItem, styles.purple)} data-qa="get-job-companies-block">
                  {`${companiesCount}+`}

                  <div className={styles.secondItem}>
                    <CourseHeroSticker
                      colorMode={StickerColorMode.Purple}
                      title={t(`${I18N_CODES.course}:students_get_job_companies_sticker_title`)}
                      originSide={StickerSide.Right}
                      width='227px'
                    />
                  </div>
                </div>
              </div>
            </div>

            <AgreementEmailPopup
              isOpen={isAgreementEmailModalOpened}
              closePopup={closeAgreementEmailModal}
              courseType={CourseType.Prepaid}
              courseSlug={courseSlug}
            />

            <EmploymentBenefitsPrepaid />
          </div>
        </div>
      </div>
    </div>
  );
});
